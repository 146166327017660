// Background colors
$primary-bg-color: #ffffff;
$secondary-bg-color: #030011;
$dark-bg-color: #030011;
$light-bg-color: #969696;
$accent-bg-color: #04afed;
$other-bg-color: #f1f1f3;

// Text colors
$primary-txt-color: #0b0c0e;
$secondary-txt-color: #04afed;
$light-txt-color: #969696;
$header-txt-color: #030011;
$paragraph-txt-color: #333333;

// Border colors
$primary-border-color: #f1f1f3;
$secondary-border-color: #04afed;
$input-border-color: #969696;


// Animation time duration
$time1: .3s;
$timing-function: cubic-bezier(0.215, 0.610, 0.355, 1);

// Media queries
$small: 'min-width: 576px';
$medium: 'min-width: 768px';
$large: 'min-width: 992px';
$xlarge: 'min-width: 1200px';