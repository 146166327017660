@font-face {
  font-family: Source-Sans-Pro;
  src: url(../fonts/SourceSansPro-Light.ttf);
  font-weight: 300;
  font-display: fallback;
}

@font-face {
  font-family: Source-Sans-Pro;
  src: url(../fonts/SourceSansPro-Regular.ttf);
  font-weight: 400;
  font-display: fallback;
}

@font-face {
    font-family: Source-Sans-Pro;
    src: url(../fonts/SourceSansPro-SemiBold.ttf);
    font-weight: 600;
    font-display: fallback;
}

@font-face {
    font-family: Source-Sans-Pro;
    src: url(../fonts/SourceSansPro-Bold.ttf);
    font-weight: 700;
    font-display: fallback;
}

@font-face {
    font-family: Source-Sans-Pro;
    src: url(../fonts/SourceSansPro-Black.ttf);
    font-weight: 900;
    font-display: fallback;
}
