.main-nav {
  position: relative;
  background-color: #fff;
  padding: 10px 0;
  box-shadow: 0 0 10px #333333;
  z-index: 9999;

  @media ($medium) {
    height: auto;
  }

  @media ($xlarge) {
    padding: 12px 0;
  }
}

.main-nav .container {
  @media ($medium) {
    max-width: none;
    padding: 0 30px;
  }

  @media ($large) {
    max-width: 960px;
    padding: 0 15px;
  }

  @media ($xlarge) {
    max-width: 1140px;
  }
}

.main-nav .mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;

  @media ($medium) {
    display: none;
  }

  img {
    width: 80px;

    @media ($small) {
      width: 100px;
    }
  }
}

.nav-list.active {
  opacity: 1;
  transform: translate(-50%, -50%);
  transition: $time1 $timing-function;
}

.nav-list {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 50px;
  background-color: $primary-bg-color;
  box-shadow: 1px 0 7px $light-bg-color;
  transition: 1s $timing-function;
  z-index: 9999;
  opacity: 1;
  will-change: transform, opacity;
  transform: translate(-200%, -50%);

  @media ($medium) {
    position: static;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    opacity: 1;
    background-color: $primary-bg-color;
    padding: 0;
    transform: none;
    box-shadow: none;
  }
}

.nav-list .menu-icon {
  position: absolute;
  top: 50px;
  right: 50px;

  @media ($medium) {
    display: none;
  }
}

.nav-list-item {
  position: relative;
  overflow: hidden;
  display: block;
  font-size: 24px;
  font-weight: 600;
  padding: 5px 0;

  @media ($medium) {
    padding: 0;
    font-size: calc(.5vw + 12px);
  }

  @media ($large) {
    font-weight: 700;
  }

  @media ($xlarge) {
    font-size: 18px;
  }
}

.nav-list-item:not(:first-child) {
  @media ($medium) {
    padding: 5px 0;
  }
}

.nav-list-item-logo {
  opacity: 1;
  transition: $time1;
  will-change: opacity;
  display: none;

  &:hover {
    opacity: 0.9;
  }
  @media ($medium) {
    display: block;
    width: 90px;
  }

  @media ($large) {
    width: 100px;
  }

  @media ($xlarge) {
    width: 120px;
  }
}

.nav-list-item-link {
  position: relative;
  color: $primary-txt-color;
}

.nav-list-item:hover .nav-list-item-link, .active {
  color: $secondary-txt-color;
}

.nav-list-item:not(:first-child) .nav-list-item-link::after {
  content: "";
  position: absolute;
  display: block;
  width: 35px;
  height: 5px;
  background-color: $accent-bg-color;
  bottom: -7px;
  left: 0;
  transition: $time1;
  transform: translateX(-35px);
  will-change: transform;
}

.nav-list-item:hover .nav-list-item-link::after {
  transform: translateX(0);
}

.menu-icon {
  position: relative;
  width: 32px;
  height: 22px;
  cursor: pointer;
}

.menu-icon .icon {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background-color: $dark-bg-color;
  opacity: 1;
  left: 0;
  top: 0;
  transform-origin: left center;
  transition: $time1 .2s;
  will-change: transform, opacity;
}

.menu-icon .bar1 {
  top: 0;
}

.menu-icon .bar2 {
  top: 50%;
}
.menu-icon .bar3 {
  top: 100%;
}

.menu-icon.change .bar1 {
  transform: rotate(45deg);
}

.menu-icon.change .bar2 {
  opacity: 0;
  width: 0%;
}

.menu-icon.change .bar3 {
  transform: rotate(-45deg);
}
