.footer {
    background-color: $secondary-bg-color;
    color: #ffffff;
}

.footer .content {
    margin-bottom: 20px;
}

.footer-title {
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-size: 18px;

    @media ($small) {
        font-size: 26px;
    }

    @media ($medium) {
        font-size: 28px;
    }

    @media ($large) {
        font-size: 32px;
    }
}

.footer a {
    color: #ffffff;

    &:hover {
        color: $secondary-txt-color;
    }
}

.footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer li {
    padding: 3px 0;
    line-height: 1.2;

    @media ($medium) {
        padding: 4px 0;
    }
}

.footer li a span {
    display: inline-block;
    margin-left: 4px;
    color: $light-txt-color * 1.4;
}

.footer-bottom {
    text-align: center;
    padding-bottom: 15px;

    @media ($medium) {
        text-align: right;
    }
}

.footer img {
    max-width: 24px;
}